import "./index.css";
import React from "react";
import { useEffect, useContext, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import { useParams, useNavigate, Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import InfoModal from "../../../components/ActiveDailyManagementComponents/shared/InfoModal";
import FooterModal from "../../../components/ActiveDailyManagementComponents/shared/footerModal";
import ContentLoader from "react-content-loader";
import PageLayout from "../../../layouts/PageLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { get, isEmpty } from "lodash";
import { API_CALL_URL } from "../../../constants";
import { API_CALL_URL_PRIVATE } from "../../../constants";
import axios from "../../../axios";
import Axios from "axios";
import { getTableRecordWithSorting } from "../../../services";
import moment from "moment";
import {
  capitalizeAllLetters,
  formatNumberWithFraction,
} from "../../../utils/helper";
import {
  setUpdateDate,
  setUpdateIndex,
  setUpdateStack,
  setExceptionIndex,
} from "../../../store/actions/Compensate";
import DescIcon from "../../../assets/img/desc.png";
import AscIcon from "../../../assets/img/asc.png";
import { ThemeContext } from "../../../providers/ThemeProvider";
import PsLogo from "../../../assets/img/pslogo.png";
import ADMImage from "../../../assets/img/biweekly_report.png";
import Button from "@atlaskit/button";

import SHIFT_FORM from "../../../constants/shiftForm";
import {
  CustomDropdown,
  DropdownIndicator,
  ChevronDown,
} from "../../../Styled/DropdownStyle";
import ButtonKit from "@atlaskit/button";
import Select from "react-select";
import { decryptedFunction, dataEnCrypt } from "../../../utils/helper";

const HeaderLoader = () => (
  <ContentLoader viewBox="0 0 280 17">
    {/* Only SVG shapes */}
    <rect x="0" y="2" rx="2" ry="2" style={{ width: "100%" }} height="17" />
  </ContentLoader>
);

const HeaderLoaderText = ({ themeMode }) => (
  <ContentLoader
    viewBox="0 0 280 10"
    backgroundColor={themeMode.theme === "DARK" ? "#333" : "#f5f6f7"}
    foregroundColor={themeMode.theme === "DARK" ? "#555" : "#eee"}
  >
    {/* Only SVG shapes */}
    <rect x="0" y="2" rx="2" ry="2" style={{ width: "100%" }} height="10" />
  </ContentLoader>
);

const TableLoader = ({ themeMode }) => (
  <ContentLoader
    viewBox="0 0 280 8"
    style={{ padding: "0" }}
    backgroundColor={themeMode.theme === "DARK" ? "#333" : "#f5f6f7"}
    foregroundColor={themeMode.theme === "DARK" ? "#555" : "#eee"}
  >
    {/* Only SVG shapes */}
    <rect x="0" y="2" rx="2" ry="2" style={{ width: "100%" }} height="8" />
  </ContentLoader>
);
const GraphLoader = ({ themeMode }) => (
  <ContentLoader
    viewBox="0 0 280 90"
    backgroundColor={themeMode.theme === "DARK" ? "#333" : "#f5f6f7"}
    foregroundColor={themeMode.theme === "DARK" ? "#555" : "#eee"}
  >
    {/* Only SVG shapes */}
    <rect x="0" y="2" rx="2" ry="2" style={{ width: "100%" }} height="150" />
  </ContentLoader>
);

const LinkLoader = ({ themeMode }) => (
  <div style={{ width: 40, height: 20 }}>
    <ContentLoader
      viewBox="0 0 80 50"
      backgroundColor={themeMode.theme === "DARK" ? "#333" : "#f5f6f7"}
      foregroundColor={themeMode.theme === "DARK" ? "#555" : "#eee"}
    >
      {/* Only SVG shapes */}
      <rect x="0" y="0" rx="0" ry="0" width="100" height="100" />
    </ContentLoader>
  </div>
);
const options = [
  { value: 'option1', label: 'Option 1' },
  { value: 'option2', label: 'Option 2' },
  { value: 'option3', label: 'Option 3' },
];

function CostCenterScorecard({ history }) {
  const dispatch = useDispatch();
  const didMountRef = useRef(false);
  const buisnessHeader = useSelector(
    (state) => state.compensate.buisnessHeader
  );
  const { department } = useParams();
  const isBuisnessUnitSet = localStorage.getItem("buisnessUnit");
  const [themeMode, setTheme] = useContext(ThemeContext);
  const [modalShow, setModalShow] = React.useState(false);
  const [footerShow, setFooterShow] = React.useState(false);
  const [tableData, setTableData] = useState([]);
  const [headData, setHeadData] = useState([]);
  const [totalvalue, setTotalvalue] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [sortType, setSortType] = useState("asc");
  const [sortName, setsortName] = useState("");
  const navigate = useNavigate();
  const [selectedRow, setSelectedRow] = useState(null);
  const [clickCount, setClickCount] = useState(0);
  const [metaData, setMetaData] = useState([]);
  const [scheduleData, setScheduleData] = useState([]);
  const [searchBoxValue, setSearchBoxValue] = useState("");
  const [costCenterList, setCostCenterList] = useState([]);
  const [page, setPage] = useState(1);
  const [cancelToken, setCancelToken] = useState(null);
  const [buisnessData, setBuisnessData] = useState({});
  const [mounted, setMounted] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [dropdownValue, setDropdownValue] = useState(undefined);
  const [isDropdownOpentwo, setIsDropdownOpentwo] = useState(false);
  const [dropdownValueTwo, setDropdownValueTwo] = useState(undefined);
  const [isDropdownOpenthree, setIsDropdownOpenThree] = useState(false);
  const [dropdownValueThree, setDropdownValueThree] = useState(undefined);
  const [allCostCenter, setAllCostCenter] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
   const [yearDateList, setYearDateList] = useState([]);

  const getTableData = async (eCost, eYear) => {
    setIsTableLoading(true);
    const parData = {
      cost_center_number: eCost,
      fiscal_year: eYear,
    };
    parData.page = page;

    try {
      const encryptData = dataEnCrypt(parData);
      const response = await axios.post(
        `${API_CALL_URL_PRIVATE}/biweeklyReporting/costCenterScorecard/getData`,
        { payloadData: encryptData }
      );
      const data = decryptedFunction(response?.data?.responseData);
      // const data = decryptedFunction(response?.data?.responseData);
      // const data = {
      //   header: {
      //     cost_center_type: "Fixed",
      //     target_type: 4,
      //     business_unit_description: "HUMAN RESOURCE",
      //     uos_volume_nbr_avg: 0,
      //   },
      //   result: [
      //     {
      //       _id: "671f6463399c71cfba396946",
      //       customer_code: 101,
      //       end_date: "21-12-2024",
      //       filter_end_date: 20241221,
      //       fiscal_year: 2024,
      //       name: "PP26",
      //       uos: 0,
      //       overtime_ftes: 0,
      //       agency_ftes: 0,
      //       edu_ftes: 0,
      //       orient_ftes: 0,
      //       worked_ftes: 0,
      //       pto_factor: 0,
      //       target_worked_ftes: 723.5408344,
      //       worked_ftes_variance: 0,
      //       non_productive_ftes: 0,
      //       paid_ftes: 0,
      //       paid_ftes_variance: 0,
      //       hpuos: 16,
      //       target: 723.5408344,
      //       regular_ftes: 0,
      //       target_paid_ftes: 523511.3390442482,
      //       performance: 0,
      //     },
      //     {
      //       _id: "671f645a399c71cfba39693a",
      //       customer_code: 101,
      //       end_date: "07-12-2024",
      //       filter_end_date: 20241207,
      //       fiscal_year: 2024,
      //       name: "PP25",
      //       uos: 0,
      //       overtime_ftes: 0,
      //       agency_ftes: 0,
      //       edu_ftes: 0,
      //       orient_ftes: 0,
      //       worked_ftes: 0,
      //       pto_factor: 0,
      //       target_worked_ftes: 723.5408344,
      //       worked_ftes_variance: 0,
      //       non_productive_ftes: 0,
      //       paid_ftes: 0,
      //       paid_ftes_variance: 0,
      //       hpuos: 16,
      //       target: 723.5408344,
      //       regular_ftes: 0,
      //       target_paid_ftes: 523511.3390442482,
      //       performance: 0,
      //     },
      //     {
      //       _id: "671f6452399c71cfba39692e",
      //       customer_code: 101,
      //       end_date: "23-11-2024",
      //       filter_end_date: 20241123,
      //       fiscal_year: 2024,
      //       name: "PP24",
      //       uos: 21,
      //       overtime_ftes: 0,
      //       agency_ftes: 0,
      //       edu_ftes: 0,
      //       orient_ftes: 0,
      //       worked_ftes: 0,
      //       pto_factor: 0,
      //       target_worked_ftes: 723.5408344,
      //       worked_ftes_variance: 0,
      //       non_productive_ftes: 0,
      //       paid_ftes: 0,
      //       paid_ftes_variance: 0,
      //       hpuos: 16,
      //       target: 723.5408344,
      //       regular_ftes: 0,
      //       target_paid_ftes: 523511.3390442482,
      //       performance: 0,
      //     },
      //     {
      //       _id: "671f6446399c71cfba396922",
      //       customer_code: 101,
      //       end_date: "09-11-2024",
      //       filter_end_date: 20241109,
      //       fiscal_year: 2024,
      //       name: "PP23",
      //       uos: 21,
      //       overtime_ftes: 0,
      //       agency_ftes: 0,
      //       edu_ftes: 0,
      //       orient_ftes: 0,
      //       worked_ftes: 0,
      //       pto_factor: 0,
      //       target_worked_ftes: 723.5408344,
      //       worked_ftes_variance: 0,
      //       non_productive_ftes: 0,
      //       paid_ftes: 0,
      //       paid_ftes_variance: 0,
      //       hpuos: 16,
      //       target: 723.5408344,
      //       regular_ftes: 0,
      //       target_paid_ftes: 523511.3390442482,
      //       performance: 0,
      //     },
      //     {
      //       _id: "66e7ae54f6952be149bef469",
      //       customer_code: 101,
      //       end_date: "26-10-2024",
      //       filter_end_date: 20241026,
      //       fiscal_year: 2024,
      //       name: "PP22",
      //       uos: 22,
      //       overtime_ftes: 0,
      //       agency_ftes: 0,
      //       edu_ftes: 0,
      //       orient_ftes: 0,
      //       worked_ftes: 0,
      //       pto_factor: 0,
      //       target_worked_ftes: 723.5408344,
      //       worked_ftes_variance: 0,
      //       non_productive_ftes: 0,
      //       paid_ftes: 0,
      //       paid_ftes_variance: 0,
      //       hpuos: 16,
      //       target: 723.5408344,
      //       regular_ftes: 0,
      //       target_paid_ftes: 523511.3390442482,
      //       performance: 0,
      //     },
      //     {
      //       _id: "66e7ae37f6952be149bef45d",
      //       customer_code: 101,
      //       end_date: "12-10-2024",
      //       filter_end_date: 20241012,
      //       fiscal_year: 2024,
      //       name: "PP21",
      //       uos: 22,
      //       overtime_ftes: 0,
      //       agency_ftes: 0,
      //       edu_ftes: 0,
      //       orient_ftes: 0,
      //       worked_ftes: 0,
      //       pto_factor: 0,
      //       target_worked_ftes: 723.5408344,
      //       worked_ftes_variance: 0,
      //       non_productive_ftes: 0,
      //       paid_ftes: 0,
      //       paid_ftes_variance: 0,
      //       hpuos: 16,
      //       target: 723.5408344,
      //       regular_ftes: 0,
      //       target_paid_ftes: 523511.3390442482,
      //       performance: 0,
      //     },
      //     {
      //       _id: "66e7ae2df6952be149bef451",
      //       customer_code: 101,
      //       end_date: "28-09-2024",
      //       filter_end_date: 20240928,
      //       fiscal_year: 2024,
      //       name: "PP20",
      //       uos: 22,
      //       overtime_ftes: 0,
      //       agency_ftes: 0,
      //       edu_ftes: 0,
      //       orient_ftes: 0,
      //       worked_ftes: 0,
      //       pto_factor: 0,
      //       target_worked_ftes: 723.5408344,
      //       worked_ftes_variance: 0,
      //       non_productive_ftes: 0,
      //       paid_ftes: 0,
      //       paid_ftes_variance: 0,
      //       hpuos: 16,
      //       target: 723.5408344,
      //       regular_ftes: 0,
      //       target_paid_ftes: 523511.3390442482,
      //       performance: 0,
      //     },
      //     {
      //       _id: "66cd586e746973852a008424",
      //       customer_code: 101,
      //       end_date: "14-09-2024",
      //       filter_end_date: 20240914,
      //       fiscal_year: 2024,
      //       name: "PP19",
      //       uos: 22,
      //       overtime_ftes: 0,
      //       agency_ftes: 0,
      //       edu_ftes: 0,
      //       orient_ftes: 0,
      //       worked_ftes: 0,
      //       pto_factor: 0,
      //       target_worked_ftes: 723.5408344,
      //       worked_ftes_variance: 0,
      //       non_productive_ftes: 0,
      //       paid_ftes: 0,
      //       paid_ftes_variance: 0,
      //       hpuos: 16,
      //       target: 723.5408344,
      //       regular_ftes: 0,
      //       target_paid_ftes: 523511.3390442482,
      //       performance: 0,
      //     },
      //     {
      //       _id: "66863671470184e4f1bf0364",
      //       customer_code: 101,
      //       end_date: "31-08-2024",
      //       filter_end_date: 20240831,
      //       fiscal_year: 2024,
      //       name: "PP18",
      //       uos: 22,
      //       overtime_ftes: 0,
      //       agency_ftes: 0,
      //       edu_ftes: 0,
      //       orient_ftes: 0,
      //       worked_ftes: 0,
      //       pto_factor: 0,
      //       target_worked_ftes: 723.5408344,
      //       worked_ftes_variance: 0,
      //       non_productive_ftes: 0,
      //       paid_ftes: 0,
      //       paid_ftes_variance: 0,
      //       hpuos: 16,
      //       target: 723.5408344,
      //       regular_ftes: 0,
      //       target_paid_ftes: 523511.3390442482,
      //       performance: 0,
      //     },
      //     {
      //       _id: "66863669470184e4f1bf0358",
      //       customer_code: 101,
      //       end_date: "17-08-2024",
      //       filter_end_date: 20240817,
      //       fiscal_year: 2024,
      //       name: "PP17",
      //       uos: 22,
      //       overtime_ftes: 0,
      //       agency_ftes: 0,
      //       edu_ftes: 0,
      //       orient_ftes: 0,
      //       worked_ftes: 0,
      //       pto_factor: 0,
      //       target_worked_ftes: 723.5408344,
      //       worked_ftes_variance: 0,
      //       non_productive_ftes: 0,
      //       paid_ftes: 0,
      //       paid_ftes_variance: 0,
      //       hpuos: 16,
      //       target: 723.5408344,
      //       regular_ftes: 0,
      //       target_paid_ftes: 523511.3390442482,
      //       performance: 0,
      //     },
      //     {
      //       _id: "66863659470184e4f1bf034c",
      //       customer_code: 101,
      //       end_date: "03-08-2024",
      //       filter_end_date: 20240803,
      //       fiscal_year: 2024,
      //       name: "PP16",
      //       fte_targets: {
      //         _id: "03-08-2024",
      //         workedFteTargetCalc: 661.2652331185444,
      //         paidFteTargetCalc: 723.5408344,
      //         ptoFactor: 0.08607061042117682,
      //       },
      //       ftes: {
      //         _id: "03-08-2024",
      //         workedFtes: 460.89025000000004,
      //         paidFtes: 504.29525,
      //       },
      //       uos: 22,
      //       overtime_ftes: 0.379,
      //       agency_ftes: 0,
      //       edu_ftes: 0,
      //       orient_ftes: 0,
      //       worked_ftes: 115.50325,
      //       pto_factor: 0.08607061042117682,
      //       target_worked_ftes: 661.2652331185444,
      //       worked_ftes_variance: 200.37498311854438,
      //       non_productive_ftes: 10.85125,
      //       paid_ftes: 126.3545,
      //       paid_ftes_variance: 219.24558439999998,
      //       hpuos: 16,
      //       target: 723.5408344,
      //       regular_ftes: 115.12424999999999,
      //       target_paid_ftes: 437328.6240335877,
      //       performance: 3461.1242498968195,
      //     },
      //     {
      //       _id: "6686362a470184e4f1bf0340",
      //       customer_code: 101,
      //       end_date: "20-07-2024",
      //       filter_end_date: 20240720,
      //       fiscal_year: 2024,
      //       name: "PP15",
      //       fte_targets: {
      //         _id: "20-07-2024",
      //         workedFteTargetCalc: 669.4359564907908,
      //         paidFteTargetCalc: 723.5408344,
      //         ptoFactor: 0.07477791900173263,
      //       },
      //       ftes: {
      //         _id: "20-07-2024",
      //         workedFtes: 466.97299999999996,
      //         paidFtes: 504.71449999999993,
      //       },
      //       uos: 22,
      //       overtime_ftes: 0.245,
      //       agency_ftes: 0,
      //       edu_ftes: 0,
      //       orient_ftes: 0,
      //       worked_ftes: 116.927,
      //       pto_factor: 0.07477791900173263,
      //       target_worked_ftes: 669.4359564907908,
      //       worked_ftes_variance: 202.46295649079082,
      //       non_productive_ftes: 9.435375,
      //       paid_ftes: 126.36237500000001,
      //       paid_ftes_variance: 218.82633440000006,
      //       hpuos: 16,
      //       target: 723.5408344,
      //       regular_ftes: 116.682,
      //       target_paid_ftes: 448194.5588704712,
      //       performance: 3546.898820716777,
      //     },
      //     {
      //       _id: "665415169e6a25750169254b",
      //       customer_code: 101,
      //       end_date: "06-07-2024",
      //       filter_end_date: 20240706,
      //       fiscal_year: 2024,
      //       name: "PP14",
      //       fte_targets: {
      //         _id: "06-07-2024",
      //         workedFteTargetCalc: 586.9533748027021,
      //         paidFteTargetCalc: 723.5408344,
      //         ptoFactor: 0.18877643541786243,
      //       },
      //       ftes: {
      //         _id: "06-07-2024",
      //         workedFtes: 419.38575000000003,
      //         paidFtes: 516.97925,
      //       },
      //       uos: 21,
      //       overtime_ftes: 0.19125,
      //       agency_ftes: 0,
      //       edu_ftes: 0,
      //       orient_ftes: 0,
      //       worked_ftes: 104.989875,
      //       pto_factor: 0.18877643541786243,
      //       target_worked_ftes: 586.9533748027021,
      //       worked_ftes_variance: 167.56762480270208,
      //       non_productive_ftes: 24.398375,
      //       paid_ftes: 129.38825,
      //       paid_ftes_variance: 206.56158440000002,
      //       hpuos: 16,
      //       target: 723.5408344,
      //       regular_ftes: 104.798625,
      //       target_paid_ftes: 344625.067158133,
      //       performance: 2663.495851888661,
      //     },
      //     {
      //       _id: "6654150d9e6a25750169253f",
      //       customer_code: 101,
      //       end_date: "22-06-2024",
      //       filter_end_date: 20240622,
      //       fiscal_year: 2024,
      //       name: "PP13",
      //       fte_targets: {
      //         _id: "22-06-2024",
      //         workedFteTargetCalc: 662.3426675132033,
      //         paidFteTargetCalc: 723.5408344,
      //         ptoFactor: 0.08458149696215221,
      //       },
      //       ftes: {
      //         _id: "22-06-2024",
      //         workedFtes: 482.83737499999995,
      //         paidFtes: 527.449875,
      //       },
      //       uos: 22,
      //       overtime_ftes: 0.539125,
      //       agency_ftes: 0,
      //       edu_ftes: 0,
      //       orient_ftes: 0,
      //       worked_ftes: 120.112625,
      //       pto_factor: 0.08458149696215221,
      //       target_worked_ftes: 662.3426675132033,
      //       worked_ftes_variance: 179.50529251320336,
      //       non_productive_ftes: 11.153125,
      //       paid_ftes: 131.26575,
      //       paid_ftes_variance: 196.09095939999997,
      //       hpuos: 16,
      //       target: 723.5408344,
      //       regular_ftes: 119.5735,
      //       target_paid_ftes: 438753.831142826,
      //       performance: 3342.485234288655,
      //     },
      //     {
      //       _id: "665414d19e6a257501692533",
      //       customer_code: 101,
      //       end_date: "08-06-2024",
      //       filter_end_date: 20240608,
      //       fiscal_year: 2024,
      //       name: "PP12",
      //       fte_targets: {
      //         _id: "08-06-2024",
      //         workedFteTargetCalc: 615.023437283422,
      //         paidFteTargetCalc: 723.5408344,
      //         ptoFactor: 0.14998102658098986,
      //       },
      //       ftes: {
      //         _id: "08-06-2024",
      //         workedFtes: 455.28525,
      //         paidFtes: 535.61775,
      //       },
      //       uos: 22,
      //       overtime_ftes: 1.41925,
      //       agency_ftes: 0,
      //       edu_ftes: 0,
      //       orient_ftes: 0,
      //       worked_ftes: 115.03575000000001,
      //       pto_factor: 0.14998102658098986,
      //       target_worked_ftes: 615.023437283422,
      //       worked_ftes_variance: 159.738187283422,
      //       non_productive_ftes: 20.083125,
      //       paid_ftes: 135.118875,
      //       paid_ftes_variance: 187.9230844,
      //       hpuos: 16,
      //       target: 723.5408344,
      //       regular_ftes: 113.6165,
      //       target_paid_ftes: 378346.0702544105,
      //       performance: 2800.097841655435,
      //     },
      //     {
      //       _id: "6609392e357b2488a48e5174",
      //       customer_code: 101,
      //       end_date: "25-05-2024",
      //       filter_end_date: 20240525,
      //       fiscal_year: 2024,
      //       name: "PP11",
      //       fte_targets: {
      //         _id: "25-05-2024",
      //         workedFteTargetCalc: 687.6982966161116,
      //         paidFteTargetCalc: 723.5408344,
      //         ptoFactor: 0.049537684785421865,
      //       },
      //       ftes: {
      //         _id: "25-05-2024",
      //         workedFtes: 475.099875,
      //         paidFtes: 499.86187499999994,
      //       },
      //       uos: 21,
      //       overtime_ftes: 1.253875,
      //       agency_ftes: 0,
      //       edu_ftes: 0,
      //       orient_ftes: 0,
      //       worked_ftes: 119.715375,
      //       pto_factor: 0.049537684785421865,
      //       target_worked_ftes: 687.6982966161116,
      //       worked_ftes_variance: 212.59842161611164,
      //       non_productive_ftes: 6.20925,
      //       paid_ftes: 125.92462499999999,
      //       paid_ftes_variance: 223.67895940000005,
      //       hpuos: 16,
      //       target: 723.5408344,
      //       regular_ftes: 118.4615,
      //       target_paid_ftes: 472963.0141501467,
      //       performance: 3755.9215614114137,
      //     },
      //     {
      //       _id: "660936d0300781567b4cfabd",
      //       customer_code: 101,
      //       end_date: "11-05-2024",
      //       filter_end_date: 20240511,
      //       fiscal_year: 2024,
      //       name: "PP10",
      //       fte_targets: {
      //         _id: "11-05-2024",
      //         workedFteTargetCalc: 671.5783736009432,
      //         paidFteTargetCalc: 723.5408344,
      //         ptoFactor: 0.07181690144986351,
      //       },
      //       ftes: {
      //         _id: "11-05-2024",
      //         workedFtes: 473.514,
      //         paidFtes: 510.15150000000006,
      //       },
      //       uos: 18,
      //       overtime_ftes: 1.186375,
      //       agency_ftes: 0,
      //       edu_ftes: 0,
      //       orient_ftes: 0,
      //       worked_ftes: 118.259625,
      //       pto_factor: 0.07181690144986351,
      //       target_worked_ftes: 671.5783736009432,
      //       worked_ftes_variance: 198.0643736009432,
      //       non_productive_ftes: 9.1875,
      //       paid_ftes: 127.447125,
      //       paid_ftes_variance: 213.38933439999994,
      //       hpuos: 16,
      //       target: 723.5408344,
      //       regular_ftes: 117.07325,
      //       target_paid_ftes: 451065.74256636086,
      //       performance: 3539.238272863047,
      //     },
      //     {
      //       _id: "6609366e300781567b4cfabc",
      //       customer_code: 101,
      //       end_date: "27-04-2024",
      //       filter_end_date: 20240427,
      //       fiscal_year: 2024,
      //       name: "PP9",
      //       fte_targets: {
      //         _id: "27-04-2024",
      //         workedFteTargetCalc: 674.6227667822951,
      //         paidFteTargetCalc: 723.5408344,
      //         ptoFactor: 0.06760926998442375,
      //       },
      //       ftes: {
      //         _id: "27-04-2024",
      //         workedFtes: 474.016375,
      //         paidFtes: 508.38812499999995,
      //       },
      //       uos: 21,
      //       overtime_ftes: 2.126875,
      //       agency_ftes: 0,
      //       edu_ftes: 0,
      //       orient_ftes: 0,
      //       worked_ftes: 120.09325,
      //       pto_factor: 0.06760926998442375,
      //       target_worked_ftes: 674.6227667822951,
      //       worked_ftes_variance: 200.60639178229513,
      //       non_productive_ftes: 8.628375,
      //       paid_ftes: 128.721625,
      //       paid_ftes_variance: 215.15270940000005,
      //       hpuos: 16,
      //       target: 723.5408344,
      //       regular_ftes: 117.966375,
      //       target_paid_ftes: 455161.488213776,
      //       performance: 3536.014156236577,
      //     },
      //     {
      //       _id: "660935ed300781567b4cfabb",
      //       customer_code: 101,
      //       end_date: "13-04-2024",
      //       filter_end_date: 20240413,
      //       fiscal_year: 2024,
      //       name: "PP8",
      //       fte_targets: {
      //         _id: "13-04-2024",
      //         workedFteTargetCalc: 656.1027161825458,
      //         paidFteTargetCalc: 723.5408344,
      //         ptoFactor: 0.09320568378615095,
      //       },
      //       ftes: {
      //         _id: "13-04-2024",
      //         workedFtes: 453.44612500000005,
      //         paidFtes: 500.054,
      //       },
      //       uos: 22,
      //       overtime_ftes: 0.861625,
      //       agency_ftes: 0,
      //       edu_ftes: 0,
      //       orient_ftes: 0,
      //       worked_ftes: 114.00775,
      //       pto_factor: 0.09320568378615095,
      //       target_worked_ftes: 656.1027161825458,
      //       worked_ftes_variance: 202.65659118254575,
      //       non_productive_ftes: 11.72625,
      //       paid_ftes: 125.73400000000001,
      //       paid_ftes_variance: 223.48683440000002,
      //       hpuos: 16,
      //       target: 723.5408344,
      //       regular_ftes: 113.146125,
      //       target_paid_ftes: 430531.92668440996,
      //       performance: 3424.1488116532514,
      //     },
      //     {
      //       _id: "65e58ace9ce1b52bed616423",
      //       customer_code: 101,
      //       end_date: "30-03-2024",
      //       filter_end_date: 20240330,
      //       fiscal_year: 2024,
      //       name: "PP7",
      //       fte_targets: {
      //         _id: "30-03-2024",
      //         workedFteTargetCalc: 662.4788730822639,
      //         paidFteTargetCalc: 723.5408344,
      //         ptoFactor: 0.08439324833459051,
      //       },
      //       ftes: {
      //         _id: "30-03-2024",
      //         workedFtes: 445.45825,
      //         paidFtes: 486.517,
      //       },
      //       uos: 20,
      //       overtime_ftes: 1.172375,
      //       agency_ftes: 0,
      //       edu_ftes: 0,
      //       orient_ftes: 0,
      //       worked_ftes: 112.796125,
      //       pto_factor: 0.08439324833459051,
      //       target_worked_ftes: 662.4788730822639,
      //       worked_ftes_variance: 217.0206230822639,
      //       non_productive_ftes: 10.304875000000001,
      //       paid_ftes: 123.101,
      //       paid_ftes_variance: 237.0238344,
      //       hpuos: 16,
      //       target: 723.5408344,
      //       regular_ftes: 111.62375,
      //       target_paid_ftes: 438934.16602439876,
      //       performance: 3565.6425701204603,
      //     },
      //     {
      //       _id: "65c36ff91a7c20b7e21d6f0c",
      //       customer_code: 101,
      //       end_date: "16-03-2024",
      //       filter_end_date: 20240316,
      //       fiscal_year: 2024,
      //       name: "PP6",
      //       fte_targets: {
      //         _id: "16-03-2024",
      //         workedFteTargetCalc: 670.9071658668119,
      //         paidFteTargetCalc: 723.5408344,
      //         ptoFactor: 0.07274457229056734,
      //       },
      //       ftes: {
      //         _id: "16-03-2024",
      //         workedFtes: 461.32487499999996,
      //         paidFtes: 497.5165,
      //       },
      //       uos: 21,
      //       overtime_ftes: 3.09275,
      //       agency_ftes: 0,
      //       edu_ftes: 0,
      //       orient_ftes: 0,
      //       worked_ftes: 116.941,
      //       pto_factor: 0.07274457229056734,
      //       target_worked_ftes: 670.9071658668119,
      //       worked_ftes_variance: 209.5822908668119,
      //       non_productive_ftes: 9.2405,
      //       paid_ftes: 126.1815,
      //       paid_ftes_variance: 226.0243344,
      //       hpuos: 16,
      //       target: 723.5408344,
      //       regular_ftes: 113.84825000000001,
      //       target_paid_ftes: 450165.2300662655,
      //       performance: 3567.6008770403387,
      //     },
      //     {
      //       _id: "65c0f29d58c5e418bf82e0fd",
      //       customer_code: 101,
      //       end_date: "02-03-2024",
      //       filter_end_date: 20240302,
      //       fiscal_year: 2024,
      //       name: "PP5",
      //       fte_targets: {
      //         _id: "02-03-2024",
      //         workedFteTargetCalc: 674.162450794392,
      //         paidFteTargetCalc: 723.5408344,
      //         ptoFactor: 0.06824546902947809,
      //       },
      //       ftes: {
      //         _id: "02-03-2024",
      //         workedFtes: 467.268375,
      //         paidFtes: 501.49300000000005,
      //       },
      //       uos: 20,
      //       overtime_ftes: 2.153375,
      //       agency_ftes: 0,
      //       edu_ftes: 0,
      //       orient_ftes: 0,
      //       worked_ftes: 117.5195,
      //       pto_factor: 0.06824546902947809,
      //       target_worked_ftes: 674.162450794392,
      //       worked_ftes_variance: 206.89407579439205,
      //       non_productive_ftes: 8.710125,
      //       paid_ftes: 126.229625,
      //       paid_ftes_variance: 222.04783439999994,
      //       hpuos: 16,
      //       target: 723.5408344,
      //       regular_ftes: 115.366125,
      //       target_paid_ftes: 454541.01859375765,
      //       performance: 3600.906036073209,
      //     },
      //     {
      //       _id: "65bf30d8709439ed8b622c13",
      //       filter_end_date: 20240217,
      //       end_date: "17-02-2024",
      //       fiscal_year: 2024,
      //       name: "PP4",
      //       customer_code: 101,
      //       fte_targets: {
      //         _id: "17-02-2024",
      //         workedFteTargetCalc: 679.2269640294942,
      //         paidFteTargetCalc: 723.5408344,
      //         ptoFactor: 0.06124584579563266,
      //       },
      //       ftes: {
      //         _id: "17-02-2024",
      //         workedFtes: 467.38374999999996,
      //         paidFtes: 497.876625,
      //       },
      //       uos: 21,
      //       overtime_ftes: 2.559625,
      //       agency_ftes: 0,
      //       edu_ftes: 0,
      //       orient_ftes: 0,
      //       worked_ftes: 118.6975,
      //       pto_factor: 0.06124584579563266,
      //       target_worked_ftes: 679.2269640294942,
      //       worked_ftes_variance: 211.84321402949422,
      //       non_productive_ftes: 8.1245,
      //       paid_ftes: 126.822,
      //       paid_ftes_variance: 225.6642094,
      //       hpuos: 16,
      //       target: 723.5408344,
      //       regular_ftes: 116.13787500000001,
      //       target_paid_ftes: 461390.86849462293,
      //       performance: 3638.098031056307,
      //     },
      //     {
      //       _id: "659a6a74774982e10dc69d83",
      //       filter_end_date: 20240203,
      //       end_date: "03-02-2024",
      //       fiscal_year: 2024,
      //       name: "PP3",
      //       customer_code: 101,
      //       fte_targets: {
      //         _id: "03-02-2024",
      //         workedFteTargetCalc: 701.2875082878386,
      //         paidFteTargetCalc: 723.5408344,
      //         ptoFactor: 0.03075614402691606,
      //       },
      //       ftes: {
      //         _id: "03-02-2024",
      //         workedFtes: 490.38275000000004,
      //         paidFtes: 505.94362500000005,
      //       },
      //       uos: 20,
      //       overtime_ftes: 2.535875,
      //       agency_ftes: 0,
      //       edu_ftes: 0,
      //       orient_ftes: 0,
      //       worked_ftes: 124.5135,
      //       pto_factor: 0.03075614402691606,
      //       target_worked_ftes: 701.2875082878386,
      //       worked_ftes_variance: 210.90475828783855,
      //       non_productive_ftes: 4.5127500000000005,
      //       paid_ftes: 129.02625,
      //       paid_ftes_variance: 217.59720939999994,
      //       hpuos: 16,
      //       target: 723.5408344,
      //       regular_ftes: 121.97762499999999,
      //       target_paid_ftes: 491825.73818017443,
      //       performance: 3811.826959089134,
      //     },
      //     {
      //       _id: "659a6945774982e10dc69d82",
      //       filter_end_date: 20240120,
      //       end_date: "20-01-2024",
      //       fiscal_year: 2024,
      //       name: "PP2",
      //       customer_code: 101,
      //       fte_targets: {
      //         _id: "20-01-2024",
      //         workedFteTargetCalc: 622.84071272469,
      //         paidFteTargetCalc: 723.5408344,
      //         ptoFactor: 0.139176832719906,
      //       },
      //       ftes: {
      //         _id: "20-01-2024",
      //         workedFtes: 422.94899999999996,
      //         paidFtes: 491.330875,
      //       },
      //       uos: 19,
      //       overtime_ftes: 2.141125,
      //       agency_ftes: 0,
      //       edu_ftes: 0,
      //       orient_ftes: 0,
      //       worked_ftes: 107.29837500000001,
      //       pto_factor: 0.139176832719906,
      //       target_worked_ftes: 622.84071272469,
      //       worked_ftes_variance: 199.89171272469002,
      //       non_productive_ftes: 17.685375,
      //       paid_ftes: 124.98375000000001,
      //       paid_ftes_variance: 232.2099594,
      //       hpuos: 16,
      //       target: 723.5408344,
      //       regular_ftes: 105.15725,
      //       target_paid_ftes: 388017.23842508584,
      //       performance: 3104.5414977953997,
      //     },
      //     {
      //       _id: "658a5409ae0a6c2de72087ae",
      //       filter_end_date: 20240106,
      //       end_date: "06-01-2024",
      //       fiscal_year: 2024,
      //       name: "PP1",
      //       customer_code: 101,
      //       fte_targets: {
      //         _id: "06-01-2024",
      //         workedFteTargetCalc: 470.0324702664314,
      //         paidFteTargetCalc: 723.5408344,
      //         ptoFactor: 0.3503718823883543,
      //       },
      //       ftes: {
      //         _id: "06-01-2024",
      //         workedFtes: 313.20187500000003,
      //         paidFtes: 482.1248750000001,
      //       },
      //       uos: 20,
      //       overtime_ftes: 1.538875,
      //       agency_ftes: 0,
      //       edu_ftes: 0,
      //       orient_ftes: 0,
      //       worked_ftes: 79.45462500000001,
      //       pto_factor: 0.3503718823883543,
      //       target_worked_ftes: 470.0324702664314,
      //       worked_ftes_variance: 156.83059526643137,
      //       non_productive_ftes: 42.565125,
      //       paid_ftes: 122.01975000000002,
      //       paid_ftes_variance: 241.4159593999999,
      //       hpuos: 16,
      //       target: 723.5408344,
      //       regular_ftes: 77.91575,
      //       target_paid_ftes: 221095.20926615462,
      //       performance: 1811.9624836647722,
      //     },
      //   ],
      // };
      console.log("bnn", data);

      setIsTableLoading(false);
      setTableData(data?.data);
    } catch (error) {
      setIsTableLoading(false);
      console.log(error);
    }
  };


  // console.log("tableDat", tab);
  

  const getTableNextData = async (pageIn) => {
    const parData = {};
    // parData.limit = 500;
    parData.page = pageIn;

    try {
      const encryptData = dataEnCrypt(parData);
      const response = await axios.post(
        `${API_CALL_URL_PRIVATE}/activeDailyManagement/getData`,
        { payloadData: encryptData }
      );
      const data = decryptedFunction(response?.data?.responseData);

      setTableData((prevData) => [
        ...prevData,
        ...(data?.data?.cost_centers || []),
      ]);

      if (data?.data?.hasNextPage) {
        await new Promise((resolve) => setTimeout(resolve, 500));
        // setTimeout(() => {
        getTableNextData(data?.data?.nextPage);
        // }, 1000);
      }
    } catch (error) {
      setIsTableLoading(false);
      console.log(error);
    }
  };

  const fetchDataRecursive = async (
    currentPage = page,
    initialCall = true,
    searchKey,
    limit,
    cancelToken,
    buisnessCategory
  ) => {
    // try {
    if (initialCall) {
      setIsTableLoading(true);
    }

    const parData = {
      page: currentPage,
      businessUnit: isBuisnessUnitSet !== null ? isBuisnessUnitSet : "",
    };

    if (searchKey) {
      parData.searchKeyword = searchKey;
    }

    if (limit) {
      parData.limit = limit;
    }

    if (buisnessCategory) {
      parData.businessUnit = buisnessCategory;
    }

    // else {
    //   parData.businessUnit = "";
    // }
    const encryptData = dataEnCrypt(parData);
    const response = await axios.post(
      `${API_CALL_URL_PRIVATE}/activeDailyManagement/getData`,
      { payloadData: encryptData },
      { cancelToken: cancelToken }
    );
    const responseData = decryptedFunction(response?.data?.responseData);
    const costCenters = responseData?.data?.cost_centers || [];

    if (searchKey || buisnessCategory || isBuisnessUnitSet !== null) {
      setTableData(costCenters);
    } else {
      setTableData((prevData) => [...prevData, ...costCenters]);
    }

    if (initialCall) {
      setIsTableLoading(false);
    }

    if (response?.data?.data?.hasNextPage) {
      await new Promise((resolve) => setTimeout(resolve, 500));
      await fetchDataRecursive(
        response?.data?.data?.nextPage,
        false,
        null,
        null,
        null,
        buisnessCategory
      );
    }
    // } catch (error) {
    //   setIsTableLoading(false);
    //   if (axios.isCancel(error)) {
    //     console.log("Request canceled:", error.message);
    //   } else {
    //     console.error("Error fetching data:", error);
    //   }
    // }
  };

  const getHeadData = async () => {
    setIsLoading(true);

    try {
      const resp = await axios.post(
        `${API_CALL_URL_PRIVATE}/activeDailyManagement/getheaderData`
      );
      const data = decryptedFunction(resp?.data?.responseData);
      setIsLoading(false);
      setHeadData(data.data);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const getCostCenterList = async (ccn) => {
     setIsTableLoading(true);
    try {
     const paramData = {
       businessUnit: isBuisnessUnitSet !== null ? isBuisnessUnitSet : "",
     };
     const encryptData = dataEnCrypt(paramData);
     const response = await axios.post(
       `${API_CALL_URL_PRIVATE}/biweeklyReporting/biweeklyGeneral/costCentersList`,
       { payloadData: encryptData }
     );

     const data = decryptedFunction(response?.data?.responseData);

     setCostCenterList(
       data?.data?.costCenterResponse?.map((item) => {
         return {
           label: `${item.cost_center_number} - ${capitalizeAllLetters(
             item.cost_center_name
           )}`,
           value: item.cost_center_number,
         };
       })
     );

      setAllCostCenter(data?.data?.costCenterResponse);

     const matchingCostCenter = data.data?.costCenterResponse.filter(
       (val) => val.cost_center_slug === department
     )?.[0];

      if (!matchingCostCenter) {
        history(`/activedailymanagement/not-found`);
      }
      setDropdownValue({
        label: `${
          matchingCostCenter?.cost_center_number
        } - ${capitalizeAllLetters(matchingCostCenter?.cost_center_name)}`,
        value: matchingCostCenter?.cost_center_number,
      });
      return matchingCostCenter;
    } catch (err) {
      console.log(err);
    }
  };

  const jumpFun = (e) => {
    let filVal = allCostCenter.filter((val) => val.cost_center_number === e);
    console.log("filVal", filVal);
    
    const url = `/biweekly/costcenterscorecard/${filVal[0]?.cost_center_slug}`;
    if (filVal[0]?.cost_center_slug) {
      history(url);
    } else {
      return "";
    }
  };




   const ppfiscalYearList = async () => {
     try {
       const parData = {
         cost_center_number: 100061102,
       };
       const encryptData = dataEnCrypt(parData);
       const response = await axios.post(
         `${API_CALL_URL_PRIVATE}/biweeklyReporting/biweeklyGeneral/ppfiscalYearList`,
         { payloadData: encryptData }
       );

       console.log("response", response);
       

       const data = decryptedFunction(response?.data);

       setYearDateList(
         data.data?.map((val) => {
           return {
             label: val,
             value: val,
           };
         })
       );

        setDropdownValueThree({
          label: data?.data[0],
          value: data?.data[0],
        });
       

       return data?.data[0] || "" ;
     } catch (error) {
       console.error("Failed to fetch user data:", error);
     } finally {
       setIsLoading(false);
     }
   };

  useEffect(() => {
    // getTableData();
    // getCostCenterList();
    fetchData();
    getRefreshSchedule();
    getBuisnesUnit();
    // ppfiscalYearList();
    didMountRef.current = true;
  }, []);

  const sortingHandle = (key, bindname = null) => {

    const dataToSort = bindname ? get(tableData, bindname, []) : tableData;
    const sortdata = getTableRecordWithSorting(dataToSort, key, sortType);
    setSortType(sortType === "asc" ? "desc" : "asc");
    activityLog(SHIFT_FORM.TABLE_NAMES[key] ?? "", sortType);


    if (bindname) {
      setTableData({ ...tableData, [bindname]: sortdata });
    } else {
      setTableData(sortdata);
    }

    setsortName(key);
  };

  const emptyActiveData = () => {
    getTableData();
    getHeadData();
    setSelectedRow(null);
    dispatch(setUpdateDate(""));
    dispatch(setUpdateIndex(""));
    dispatch(setExceptionIndex(""));
    dispatch(setUpdateStack(""));
  };

  const handleDoubleExceptional = (val) => {
    navigate(`/activedailymanagement/adm-summary/${val?.cost_center_slug}`);
  };

  const getMetaData = async () => {
    try {
      const param = {
        application_id: 103,
      };
      const encryptData = dataEnCrypt(param);
      const response = await axios.post(
        `${API_CALL_URL_PRIVATE}/metaData`,
        {
          payloadData: encryptData,
        }
      );
      const data = decryptedFunction(response?.data?.responseData);
      setMetaData(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    document.title = metaData?.meta_title || "Biweekly Reporting";
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    link.href = `${metaData?.favicon}` || PsLogo;
  }, [metaData]);

  const getRefreshSchedule = async (refreshType) => {
    try {
      const parData = {};
      if (refreshType) {
        parData.clicked = 1;
      }

      const encryptData = dataEnCrypt(parData);

      const response = await axios.post(
        `${API_CALL_URL_PRIVATE}/admGeneral/fileRefreshSchedule`,
        { payloadData: encryptData }
      );
      const data = decryptedFunction(response?.data?.responseData);
      setScheduleData(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getSearchInput = async (key) => {
    //  setIsTableLoading(true);
    try {
      if (cancelToken) {
        cancelToken.cancel("Operation canceled due to new request.");
      }
      const newCancelToken = Axios.CancelToken.source();
      setCancelToken(newCancelToken);

      if (key !== "") {
        fetchDataRecursive(page, true, key, 1000, newCancelToken.token);
      } else {
        fetchDataRecursive(page, true, key, null, newCancelToken.token);
      }

      // const { data } = await axios.post(
      //   `${API_CALL_URL_PRIVATE}/activeDailyManagement/getData`,
      //   parData
      // );

      // setTableData(data.data?.cost_centers);

      // if (data?.data?.hasNextPage) {
      //   getTableNextData(data?.data?.nextPage);
      // }
      // setIsTableLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const toggleOpenThree = () => {
    setIsDropdownOpenThree((prevIsOpen) => !prevIsOpen);
  };

  const selectStyles = {
    control: (provided) => ({ ...provided, minWidth: 150, margin: 8 }),
    menu: () => ({ boxShadow: "inset 0 1px 0 rgba(0, 0, 0, 0.1)" }),
  };

  const getBuisnesUnit = async (ccn) => {
    try {
      const resp = await axios.post(
        `${API_CALL_URL_PRIVATE}/admGeneral/BusinessUnitDescriptionList`
      );
      const data = decryptedFunction(resp?.data?.responseData);

      setBuisnessData(
        data?.data?.data?.map((val) => {
          return {
            label: val,
            value: val,
          };
        })
      );
    } catch (err) {
      console.log(err);
    }
  };


  const buissnessUnitUpdate = async (value) => {
    try {
      const { data } = await axios.post(
        `${API_CALL_URL_PRIVATE}/admGeneral/businessUnitDescriptionUpdate`,
        {
          business_unit_description: value,
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (isBuisnessUnitSet !== null) {
      setDropdownValueThree({
        label: isBuisnessUnitSet,
        value: isBuisnessUnitSet,
      });
    }

  }, [isBuisnessUnitSet]);


  // useEffect(() => {
  //   if (mounted) {
  //     fetchDataRecursive();
  //   } else {
  //     setMounted(true);
  //   }
  // }, [buisnessHeader]);

  const activityLog = async (table_column, sortType) => {
    try {
      const paramData = {
        activity_id: 47,
        page_type: "Active Daily Management",
        application_id: 103,
        table_name: "Active Daily Management",
        table_column: table_column,
        sort_order: sortType === "asc" ? "Ascending" : "Descending",
        searchKeyword: searchBoxValue,
        businessUnit: isBuisnessUnitSet,
      };
      const encryptData = dataEnCrypt(paramData);
      const response = await axios.post(
        `${API_CALL_URL_PRIVATE}/activity-log/create`,
        { payloadData: encryptData }
      );
    } catch (err) {
      console.log(err);
    }
  };

    const toggleOpen = () => {
      setIsDropdownOpen((prevIsOpen) => !prevIsOpen);
    };

    const toggleOpenTwo = () => {
      setIsDropdownOpentwo((prevIsOpen) => !prevIsOpen);
    };

    useEffect(() => {}, [isDropdownOpen, dropdownValue]);

    const formatDateToDDMMYYYY = (dateString) => {
      return moment(dateString, "DD-MM-YYYY")?.format("MM/DD/YYYY");
    };



    const fetchData = async (ccn) => {
      try {
        const Costlist = await getCostCenterList(ccn);
        const Yearlist = await ppfiscalYearList();
        await getTableData(Costlist?.cost_center_number, Yearlist);
        setIsLoading(false);
        setIsTableLoading(false);
      } catch (error) {
        console.log(error);
      }
    };

  return (
    <PageLayout>
      <div className="activedailymanagement-s">
        {/* <Row className="gx-2 mt-0 align-items-center">
          <Col className="d-flex " md={{ span: 2 }}>
            
          </Col>
          <Col md={{ span: 8 }}>
            <Row className="mt-2 justify-content-center">
              <Col md={3}>
                <Select
                  defaultValue={selectedOption}
                  onChange={setSelectedOption}
                  options={options}
                />
              </Col>
              <Col md={3}>
                <Select
                  defaultValue={selectedOption}
                  onChange={setSelectedOption}
                  options={options}
                />
              </Col>
              <Col md={3}>
                <Select
                  defaultValue={selectedOption}
                  onChange={setSelectedOption}
                  options={options}
                />
              </Col>
              <Col md={3}>
                <Select
                  defaultValue={selectedOption}
                  onChange={setSelectedOption}
                  options={options}
                />
              </Col>
            </Row>
          </Col>
         

          <InfoModal show={modalShow} onHide={() => setModalShow(false)} />
          <FooterModal
            show={footerShow}
            dataFooter={scheduleData}
            onHide={() => setFooterShow(false)}
          />
        </Row> */}

        <Row className="gx-2  mt-2 mb-2 align-items-center">
          <Col md={{ span: 1 }}>
            <Link
              to={`/biweekly`}
              className="f-14 white-text-dark-mode"
              // onClick={() => {
              //     dispatch(setUpdateDate(""));
              //     dispatch(setUpdateIndex(""));
              // }}
            >
              <FontAwesomeIcon icon="fa-solid fa-arrow-left " /> Back
            </Link>
          </Col>
          <Col md={{ span: 10 }}>
            <div className="d-flex mobile-flex-wrap justify-content-center align-items-center Bi_Su">
              <div>
                <h3 className="text-center color-black m-0">
                  Cost Center Scorecard -
                </h3>
              </div>

              <div className="custom-select-v">
                <CustomDropdown
                  isDropdownOpen={isDropdownOpen}
                  onClose={toggleOpen}
                  target={
                    <Button
                      iconAfter={<ChevronDown />}
                      onClick={toggleOpen}
                      isSelected={isDropdownOpen}
                    >
                      {dropdownValue ? dropdownValue.label : ""}
                    </Button>
                  }
                >
                  <Select
                    autoFocus
                    backspaceRemovesValue={false}
                    components={{ DropdownIndicator, IndicatorSeparator: null }}
                    controlShouldRenderValue={false}
                    hideSelectedOptions={false}
                    isClearable={false}
                    menuIsOpen={isDropdownOpen}
                    onChange={(e) => {
                      toggleOpen();
                      jumpFun(e?.value);
                      setDropdownValue(e);
                      getTableData(e?.value, dropdownValueThree?.value);
                    }}
                    options={costCenterList}
                    placeholder="Search..."
                    styles={selectStyles}
                    tabSelectsValue={false}
                    value={dropdownValue}
                  />
                </CustomDropdown>
              </div>

              {/* <div className="custom-select-v">
                <CustomDropdown
                  isDropdownOpen={isDropdownOpentwo}
                  onClose={toggleOpenTwo}
                  target={
                    <Button
                      iconAfter={<ChevronDown />}
                      onClick={toggleOpenTwo}
                      isSelected={isDropdownOpentwo}
                    >
                      08/10/2024
                    </Button>
                  }
                >
                  <Select
                    autoFocus
                    backspaceRemovesValue={false}
                    components={{ DropdownIndicator, IndicatorSeparator: null }}
                    controlShouldRenderValue={false}
                    hideSelectedOptions={false}
                    isClearable={false}
                    menuIsOpen={isDropdownOpentwo}
                    options={filterDateList}
                    placeholder="Search..."
                    styles={selectStyles}
                    tabSelectsValue={false}
                    onChange={(e) => {
                      toggleOpenTwo();
                      setDropdownValueTwo(e);
                      getTableData(dropdownValue?.value, e?.value);
                    }}
                    value={dropdownValueTwo}
                  />
                </CustomDropdown>
              </div> */}

              <div className="custom-select-v">
                <CustomDropdown
                  isDropdownOpen={isDropdownOpenthree}
                  onClose={toggleOpenThree}
                  target={
                    <Button
                      iconAfter={<ChevronDown />}
                      onClick={toggleOpenThree}
                      isSelected={isDropdownOpenthree}
                    >
                      {dropdownValueThree ? dropdownValueThree.label : ""}
                    </Button>
                  }
                >
                  <Select
                    autoFocus
                    backspaceRemovesValue={false}
                    components={{ DropdownIndicator, IndicatorSeparator: null }}
                    controlShouldRenderValue={false}
                    hideSelectedOptions={false}
                    isClearable={false}
                    menuIsOpen={isDropdownOpenthree}
                    options={yearDateList}
                    placeholder="Search..."
                    styles={selectStyles}
                    tabSelectsValue={false}
                    onChange={(e) => {
                      toggleOpenThree();
                      setDropdownValueThree(e);
                      getTableData(dropdownValue?.value, e?.value);
                    }}
                    value={dropdownValueThree}
                  />
                </CustomDropdown>
              </div>
            </div>
          </Col>
          <Col md={12} className="text-end mobile-center mobile-my-2"></Col>

          <InfoModal show={modalShow} onHide={() => setModalShow(false)} />
          <FooterModal
            show={footerShow}
            dataFooter={scheduleData}
            onHide={() => setFooterShow(false)}
          />
        </Row>

        <Row className="mt-3 gx-2">
          <Col className="mobile-half">
            <div class="position-relative  h-100 box-h col-bg">
              <div class="d-flex align-items-center justify-content-center">
                <div class="i d-flex align-items-center">
                  <span class="big-num  f-16 lh-1 ">Cost Center Type : </span>
                </div>
                <div class="ps-2">
                  <div class="pe-0 primary-font">
                    <span class="f-16 color-black fw-600 ">
                      {tableData?.header?.cost_center_type}
                    </span>
                    &nbsp;
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col className="mobile-half">
            <div class="position-relative  h-100 box-h col-bg">
              <div class="d-flex align-items-center justify-content-between">
                <div class="i d-flex align-items-center">
                  <span class="big-num  f-16 lh-1 ">Target Type : </span>
                </div>
                <div class="ps-2">
                  <div class="pe-0 primary-font">
                    <span class="f-16 color-black fw-600 ">
                      {tableData?.header?.target_type}
                    </span>
                    &nbsp;
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col className="mobile-half">
            <div class="position-relative  h-100 box-h col-bg">
              <div class="d-flex align-items-center justify-content-between">
                <div class="i d-flex align-items-center">
                  <span class="big-num  f-16 lh-1 ">
                    Units of Service Description :{" "}
                  </span>
                </div>
                <div class="ps-2">
                  <div
                    class="pe-0 primary-font"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    <span class="f-16 color-black fw-600 ">
                      {tableData?.header?.business_unit_description || "N/A"}
                    </span>
                    &nbsp;
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col className="mobile-half">
            <div class="position-relative  h-100 box-h col-bg">
              <div class="d-flex align-items-center justify-content-between">
                <div class="i d-flex align-items-center">
                  <span class="big-num  f-16 lh-1 ">
                    Budgeted UOS Anual Avg Age :{" "}
                  </span>
                </div>
                <div class="ps-2">
                  <div class="pe-0 primary-font">
                    <span class="f-16 color-black fw-600 ">
                      {
                        formatNumberWithFraction(tableData?.header
                          ?.uos_volume_nbr_avg, 1)
                      }
                    </span>
                    &nbsp;
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>

        <Row className="gx-2">
          <Col md={12} className="mt-2 h-6100">
            <div className="bg-white p-3 h-100">
              <Table
                responsive
                className="table activity-tabel mb-0 ind-table tb2  table-hover exceptions-table scroll-table first-t fix-column-width"
              >
                <thead className="all-side-border">
                  <tr className="vertical-align-middle">
                    <th className="border-0"></th>
                    <th className="border-0"></th>
                    <th className="border-0"></th>
                    <th colSpan={8}>Worked</th>
                    <th colSpan={4}>Paid</th>
                    <th colSpan={3} className="border-0"></th>
                  </tr>
                  <tr className="vertical-align-middle">
                    <th className="border-0"></th>
                    <th className="border-0"></th>
                    <th>Volume</th>
                    <th colSpan={3}>Direct Productive</th>
                    <th colSpan={2}>Indirect Productive</th>
                    <th colSpan={3}>Total Productive(Direct + Indirect)</th>
                    <th>Non Productive</th>
                    <th colSpan={3}>Total Productive(Direct + Indirect)</th>
                    <th colSpan={3}>HPUOS Variance</th>
                  </tr>
                  <tr className="vertical-align-middle w-93">
                    <th>
                      Pay
                      <br />
                      Period
                      <br />
                      Name
                    </th>
                    <th>End Date</th>
                    <th>UOS</th>
                    <th>
                      Regular
                      <br />
                      FTEs
                    </th>
                    <th>
                      Overtime
                      <br />
                      FTEs
                    </th>
                    <th>
                      Agency
                      <br />
                      FTEs
                    </th>
                    <th>
                      EDU
                      <br />
                      FTEs
                    </th>
                    <th>
                      Orient
                      <br />
                      FTEs
                    </th>
                    <th>
                      Worked
                      <br />
                      FTEs
                    </th>
                    <th>
                      Target Worked
                      <br />
                      FTEs
                    </th>
                    <th>
                      Worked FTEs
                      <br />
                      Variance From
                      <br />
                      Target
                    </th>
                    <th>
                      Non-
                      <br />
                      Productive
                      <br />
                      FTEs
                    </th>
                    <th>
                      Paid
                      <br />
                      FTEs
                    </th>
                    <th>
                      Target Paid
                      <br />
                      FTEs
                    </th>
                    <th>
                      Paid FTEs
                      <br />
                      Varience From
                      <br />
                      Target
                    </th>
                    <th>HPUOS</th>
                    <th>Target</th>
                    <th>
                      Performance
                      <br />
                      Percentage
                    </th>
                  </tr>
                </thead>

                <tbody className="ftr">
                  {!isTableLoading ? (
                    tableData?.result?.length > 0 ? (
                      tableData?.result?.map((val, ind) => {
                        return (
                          <tr>
                            <td className="text-center">
                              {val?.fiscal_year_name}
                            </td>
                            <td className="text-center">
                              {formatDateToDDMMYYYY(val?.end_date)}
                            </td>
                            <td className=" text-center">
                              {formatNumberWithFraction(val?.uos, 0)}
                            </td>
                            <td className=" text-center">
                              {formatNumberWithFraction(val?.regular_ftes, 1)}
                            </td>
                            <td className="text-center">
                              {formatNumberWithFraction(val?.overtime_ftes, 1)}
                            </td>
                            <td className="color-red text-center">
                              {" "}
                              {formatNumberWithFraction(val?.agency_ftes, 1)}
                            </td>
                            <td className=" text-center">
                              {formatNumberWithFraction(val?.edu_ftes, 1)}
                            </td>
                            <td className=" text-center">
                              {" "}
                              {formatNumberWithFraction(val?.orient_ftes, 1)}
                            </td>
                            <td className="text-center">
                              {formatNumberWithFraction(val?.worked_ftes, 1)}
                            </td>
                            <td className="text-center">
                              {formatNumberWithFraction(
                                val?.target_worked_ftes,
                                1
                              )}
                            </td>
                            <td className="color-red text-center">
                              {" "}
                              {formatNumberWithFraction(
                                val?.worked_ftes_variance,
                                1
                              )}
                            </td>
                            <td className=" text-center">
                              {formatNumberWithFraction(
                                val?.non_productive_ftes,
                                1
                              )}
                            </td>
                            <td className=" text-center">
                              {formatNumberWithFraction(val?.paid_ftes, 1)}
                            </td>
                            <td className="text-center">
                              {" "}
                              {formatNumberWithFraction(
                                val?.target_paid_ftes,
                                1
                              )}
                            </td>
                            <td className="color-red text-center">
                              {formatNumberWithFraction(
                                val?.paid_ftes_variance,
                                1
                              )}
                            </td>
                            <td className=" text-center">
                              {formatNumberWithFraction(val?.hpuos, 3)}
                            </td>
                            <td className=" text-center">
                              {formatNumberWithFraction(val?.target, 3)}
                            </td>
                            <td className="text-center">
                              {formatNumberWithFraction(val?.performance, 1)}%
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ height: "200px" }}
                      >
                        <p className="text-secondary display-6">
                          No Data Found
                        </p>
                      </div>
                    )
                  ) : (
                    <div>
                      <TableLoader themeMode={themeMode} />
                      <TableLoader themeMode={themeMode} />
                      <TableLoader themeMode={themeMode} />
                      <TableLoader themeMode={themeMode} />
                      <TableLoader themeMode={themeMode} />
                      <TableLoader themeMode={themeMode} />
                      <TableLoader themeMode={themeMode} />
                      <TableLoader themeMode={themeMode} />
                      <TableLoader themeMode={themeMode} />
                      <TableLoader themeMode={themeMode} />
                      <TableLoader themeMode={themeMode} />
                      <TableLoader themeMode={themeMode} />
                      <TableLoader themeMode={themeMode} />
                      <TableLoader themeMode={themeMode} />
                    </div>
                  )}
                </tbody>
              </Table>
            </div>
          </Col>
          <Col md={12} className="ft-text mt-2 mb-2 px-1 ft-text">
            <div className="row  f-14 d-flex justify-content-between gx-2 m-0">
              <div className="col-12 text-end text-mobile-center bg-white">
                <span
                  className="d-inline-block p-2  cursor-pointer"
                  onClick={() => {
                    getRefreshSchedule(true);
                    setFooterShow(true);
                  }}
                >
                  {scheduleData?.exception?.currentFileMsg}
                </span>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </PageLayout>
  );
}

export default CostCenterScorecard;
